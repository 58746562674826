@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-form-style {
  &.wizard {
    max-width: 700px; // default full page forms width
  }
  padding: var(--box-padding-admin);
  background-color: var(--color-element-layer1);
  border-radius: var(--border-radius-lg);
  box-shadow: none;

  // Enables responsive breakpoint (https://worximity.atlassian.net/l/c/dsedSkS0)
  &.container {
    margin-left: 0;

    // Responsive columns within forms
    .row {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  // All fieldsets
  ::v-deep fieldset:not(.form-footer-actions) {
    border-color: transparent;
    border-width: 0;
    padding-bottom: var(--field-vertical-gutter);

    // fieldset
    &.inline-fields,
    &.multiple-inline-fields {
      display: flex;
    }
    &.inline-fields,
    &.multiple-inline-fields,
    &.footer-action-buttons {
      // Responsive Columns
      .row {
        margin-top: 0;
        margin-bottom: 0;
        .field-col {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
    &.multiple-inline-fields {
      justify-content: space-between;
    }
    &.inline-fields {
      &.equal-space-between-fields {
        justify-content: space-between;
      }

      .v-text-field {
        flex: 0 0 auto;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  ::v-deep legend,
  ::v-deep .legend,
  ::v-deep .legend-header {
    margin-top: var(--box-padding-admin);
    margin-bottom: calc(var(--field-vertical-gutter) * 2);
  }
  ::v-deep header.legend-header {
    margin-bottom: 0;
  }
  ::v-deep legend.legend-header {
    font-size: var(--font-size-h2);
    line-height: 1.2;

    &.top-legend {
      margin-bottom: var(--box-padding-admin);
    }
  }
  ::v-deep .legend-header {
    &,
    h2,
    h3,
    .h1,
    .h2,
    .h3,
    .wx-typo-h1,
    .wx-typo-h2,
    .wx-typo-h3 {
      font-weight: normal;
    }
  }
  ::v-deep .legend-vertical-placeholder {
    margin-top: 24px;

    @media ($wx-xl-min) {
      margin-top: 29px;
    }
  }

  // Display As Columns
  ::v-deep .narrow-field-width,
  ::v-deep .average-field-width,
  ::v-deep .wide-field-width,
  ::v-deep .huge-field-width {
    &.single-field-row {
      display: block;
    }
  }

  ::v-deep .narrow-field-width,
  ::v-deep .average-field-width,
  ::v-deep .wide-field-width,
  ::v-deep .huge-field-width {
    width: 100%;
  }
  ::v-deep .narrow-field-width {
    max-width: 160px;
  }
  ::v-deep .medium-field-width {
    max-width: 410px;
  }
  ::v-deep .average-field-width {
    max-width: 560px;
  }
  ::v-deep .wide-field-width {
    max-width: 900px;
  }

  ::v-deep .bottom-add-row {
    padding-top: var(--box-padding-admin);
    padding-bottom: var(--box-padding-admin);

    .col {
      .v-btn {
        margin-left: auto; // aligned to right
      }
    }
  }

  /** - - - - - - - - - - - - - -
   * Vuetify Stepper
   * Hack default styles
   */
  ::v-deep .v-stepper {
    // Reset Vuetify. Prevents conflict with parent .wx-form-style
    &.v-sheet,
    &__header {
      box-shadow: none;
      background-color: transparent;
      border-color: transparent;
    }

    &__header {
      // Native Dividers do NOT support color props
      .v-divider {
        border-top-width: 2px;
        // Hack Vutify since native Dividers do NOT support color prop
        &,
        &[color] {
          border-color: var(--color-border-theme); // inactive color
        }
        &[color="primary"] {
          border-color: var(--color-primary); // active color
        }
      }
    }
    &:not(.v-stepper--alt-labels) {
      .v-stepper__header {
        .v-divider {
          // Eliminate gutters between Steps and Dividers
          margin-right: -21px;
          margin-left: -24px;
          @media ($wx-md-min) {
            margin-left: -16px;
          }
        }
      }
    }
    // alt-labels are stack vertically under the step numbers
    &--alt-labels {
      .v-stepper__header {
        .v-divider {
          margin-top: 39px;
          // Eliminate gutters between Steps and Dividers
          margin-right: -70px;
          margin-left: -73px;
        }
        .v-stepper__label {
          align-items: center; // Over-rule Vutify to fix a bug
        }
      }
    }

    &__step {
      .v-stepper {
        // Default steps (NOT inactive & NOT active)
        &__step__step {
          $circle_dimension: 32px;
          height: $circle_dimension;
          width: $circle_dimension;
          min-width: $circle_dimension;
          margin-left: -3px;
          font-size: 0.88rem;
          background-color: var(--color-primary) !important;
          border: 2px solid var(--color-primary);
        }
        &__label {
          color: var(--color-text-subtle-theme);

          .caption {
            // font-size is from Vuetify
            color: var(--color-text-subtle-theme);
          }
        }
      }

      // v-stepper__step--inactive
      &--inactive {
        .v-stepper {
          // Step number
          &__step__step {
            background-color: transparent !important;
            border-color: var(--color-border-theme);

            &:not(.primary) {
              color: var(--color-text-subtle-theme);
            }
          }
          &__label {
            align-items: center;
            color: var(--color-text-theme);
          }
        }
      }

      // v-stepper__step--active
      &--active {
        .v-stepper {
          // Active step number
          &__step__step {
            position: relative;
            color: transparent; // hidden
            background-color: var(--color-base-background) !important;
            &::after {
              content: "";
              position: absolute;
              top: auto;
              right: auto;
              bottom: auto;
              left: auto;
              display: inline-block;
              width: 10px;
              height: 10px;
              background-color: var(--color-primary) !important;
              border-radius: 50%;
            }
          }
          &__label {
            .caption {
              //color: var(--color-text-subtle-theme);
              opacity: 0.5;
            }
          }
        }
      }

      // v-stepper__step--error
      &--error {
        // Step number
        .v-stepper {
          &__step__step {
            border-color: var(--color-error);
            background-color: var(--color-base-background) !important;
            .v-icon {
              margin-top: -4px;
              color: var(--color-error);
            }
            &::after {
              display: none;
            }
          }
          &__label {
            color: var(--color-error);
          }
        }
        &.v-stepper__step--active .v-stepper__label {
          color: var(--color-text-theme);
        }
      }
    }

    // v-stepper__wrapper
    &__wrapper {
      .row {
        margin-top: 0;
        margin-bottom: 0;

        > .col:not(.step-footer-actions) {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    // Vertical stroke
    &.v-stepper--vertical {
      .v-stepper__content {
        border-left-color: var(--color-border-theme);
        border-left-width: 2px;
      }
    }
  }

  /** - - - - - - - - - - - - - -
   * Expansion Panel Fieldset
   * `v-expansion-panel--fieldset-toggle`
   */
  ::v-deep .v-expansion-panel {
    &--fieldset-toggle {
      background-color: transparent !important;
      border-radius: 0;

      &.v-expansion-panel--active {
        border-radius: 0;
        > .v-expansion-panel-header {
          min-height: inherit;
        }
      }

      &::before {
        box-shadow: none;
      }
      .v-expansion-panel-header {
        padding-inline: 0;
        font-size: var(--font-size-norm);
        border-top: 1px solid var(--color-border-theme);
        border-radius: 0;
        background-color: transparent;
        transition: var(--smooth-transition);

        &:hover,
        &:focus {
          background-color: var(--color-control-subtle-theme);
        }
      }
      .v-expansion-panel-content {
        margin-right: 0.72rem;
        border-right: 1px solid var(--color-text-theme);
        border-image: linear-gradient(to bottom, var(--color-text-theme), var(--color-border-theme)) 1 100%;

        &__wrap {
          padding: 0.5rem var(--wx-panel-container-padding) 0.5rem 0;
          margin-bottom: -1px;
          border-bottom: 1px solid var(--color-border-theme);
        }
      }
    }
  }
}

@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.form-footer-actions {
  display: flex;
  margin-top: 3rem;
  border-color: transparent;
  border-radius: 0;

  ::v-deep .delete-btn {
    order: 3;
  }
  .cancel-btn {
    order: 2;
  }
  .submit-btn {
    order: 1;
  }

  // vertical phone only
  @media (max-width: $wx-max-width-phone-vertical) {
    flex-direction: column;
    padding-top: var(--box-padding-admin);
    border-top: 1px solid var(--color-border-theme);

    .v-btn,
    ::v-deep .v-btn.dialog-activator-btn {
      margin-top: var(--field-vertical-gutter);
    }
  }
  // larger then a vertical phone
  @media (min-width: $wx-min-width-phone-vertical) {
    flex-direction: row-reverse;
    padding-top: var(--field-vertical-gutter);

    .v-btn,
    ::v-deep .v-btn.dialog-activator-btn {
      margin-right: var(--box-padding-admin);

      &:first-child {
        margin-right: 0;
      }
      &.delete-btn {
        margin-right: auto; // align left
      }
    }
  }
}
